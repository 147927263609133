<template>
  <div class="page-root">
    <page-title color="blue">
      <template v-slot:right>結果</template>
    </page-title>

    <div class="display-flex answer-thanks">
      <div class="text">
        <div class="title">
          セルフ問診は以上になります。
        </div>
        <div class="description">
          ※セルフ問診は、ご自身の確認用ではないため、判定結果は表示されません
        </div>
      </div>
      <div class="img">
        <img src="@/assets/adhd/resultB-doctor.png" />
      </div>
    </div>

    <div class="answer-result">
      <div class="title">
        あなたのセルフ問診の回答結果
      </div>
      <answer-result
        :questions="questions"
        :answers="answers"
        color="blue"
        title=""
        :displayNum="3"
      />
    </div>

    <div class="copy">
      <common-button @click="onCopy" color="blue">
        回答結果のURLをコピー
      </common-button>
      <div class="text">
        ※回答結果はブックマークや印刷をして診察時にお持ちください
      </div>
    </div>

    <div class="next-button">
      <div class="text">
        回答結果は後でも保存することができます（ネット相談の場合は、自動で医師に送信します）
      </div>
      <div class="link">
        <link-button
          link="AdhdThirdResult"
          color="white"
          background-color="blue"
        >
          次へ進む
        </link-button>
      </div>
    </div>

    <v-snackbar v-model="isShowToast" :timeout="2000" :centered="true">
      <div class="snack-bar">
        クリップボードにURLをコピーしました
      </div>
    </v-snackbar>
    <ProgressModal :is-modal-active="isSaveResult" />
  </div>
</template>

<script>
import rootStore from "@/store";
import PageTitle from "@/components/adhd/PageTitle";
import AnswerResult from "@/components/adhd/AnswerResult";
import CommonButton from "@/components/adhd/CommonButton";
import LinkButton from "@/components/adhd/LinkButton";
import ProgressModal from "@/components/adhd/ProgressModal";
import { partBQuestions } from "@/data/adhd";
import { urlMixin } from "@/mixin/adhd";
const state = rootStore.state;
export default {
  name: "AdhdSecondResult",
  head: {
    title: {
      inner: "かんたんADHDセルフチェック"
    }
  },
  mixins: [urlMixin],
  data: function() {
    return {
      questions: partBQuestions(),
      answers: state.adhd.screeningAnswersBpart,
      isShowToast: false,
      isSaveResult: true
    };
  },
  components: {
    AnswerResult,
    CommonButton,
    PageTitle,
    LinkButton,
    ProgressModal
  },
  beforeCreate: function() {
    const params = {
      answer: {
        content: {
          version: "1.0",
          a: state.adhd.screeningAnswersApart,
          b: state.adhd.screeningAnswersBpart
        }
      }
    };
    let promise = null;
    if (state.adhd.answerId) {
      const url = new URL(
        `v2/api/self_checker/adult_adhd_answer/${state.adhd.answerId}`,
        process.env.VUE_APP_API_BASE_URL
      ).toString();
      promise = this.axios.patch(url, params, { withCredentials: true });
    } else {
      const url = new URL(
        "v2/api/self_checker/adult_adhd_answer",
        process.env.VUE_APP_API_BASE_URL
      ).toString();
      promise = this.axios.post(url, params, { withCredentials: true });
    }
    // やり直し際に新しく積むので、成功したらStateはリセットする
    // 最終的に全部回答したIDはV2側への遷移用に取っておく
    promise
      .then(() => {
        this.$store.commit("adhd/changeLastAnswerId", {
          lastAnswerId: state.adhd.answerId
        });
        this.$store.commit("adhd/resetAnswerId");
        this.isSaveResult = false;
      })
      .catch(() => {
        this.$router.push({
          name: "AdhdError",
          params: { backLink: "AdhdSecondScreening" }
        });
      });
  },
  created() {
    this.sendGa("click", "adhd", "complete-second-screening");
  },
  methods: {
    onCopy() {
      navigator.clipboard
        .writeText(this.answerResultUrl)
        .then(() => (this.isShowToast = true));
    }
  }
};
</script>

<style scope>
@import "../../assets/style/adhd.css";

.answer-thanks {
  background-color: #f8f8f8;
}
.answer-thanks .text {
  flex: 1;
}
.answer-thanks .text .title {
  margin-top: 24px;
  margin-left: 16px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color: #324646;
}
.answer-thanks .text .description {
  margin-top: 8px;
  margin-left: 16px;
  font-size: 13px;
  text-align: left;
}
.answer-thanks .img {
  width: 104px;
  height: 104px;
  margin-right: 12px;
  margin-top: 16px;
}
.answer-result {
  margin-top: 28px;
}
.answer-result .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.copy {
  margin: 32px 16px 8px 16px;
}
.copy .text {
  margin-top: 8px;
  font-size: 14px;
  text-align: left;
}
.next-button {
  margin-top: 24px;
  background-color: #f0f8fa;
}
.next-button .text {
  padding: 24px 16px;
  font-size: 15px;
  text-align: left;
}
.next-button .link {
  padding: 16px 16px 24px 16px;
}
</style>
